import { SUB_SYSTEM_TYPES, SYSTEM_COMMANDS_TYPES } from './system-command';

// IPC command types
export type commandAdditionalInfo = {
    message: string;
    component: string;
    pageUrl?: string;
};

// commands
export const APPLICATION_MANAGER = {
    RESTART: {
        application: {
            subsystem: SUB_SYSTEM_TYPES.APPLICATION_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.RESTART,
        },
    },
    STOP_WATCHDOG: {
        application: {
            subsystem: 'APPLICATION_MANAGER',
            command: 'STOP_WATCHDOG',
        },
    },
};

export const FILE = {
    READ_FILE: {
        file: {
            subsystem: SUB_SYSTEM_TYPES.FILE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.READ_FILE,
            path: '<ex: /opt/amwell/config/app/DOMAIN>',
            // contents field will be added in the response with the same structure
            // contents: '<ex: amwellintegration.com>' || {},
        },
    },
    WRITE_FILE: {
        file: {
            subsystem: SUB_SYSTEM_TYPES.FILE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.WRITE_FILE,
            path: '<ex: /opt/amwell/config/app/DOMAIN>',
            contents: '<ex: amwellintegration.com>' || {},
            append: false,
        },
    },
    REMOVE_FILE: {
        file: {
            subsystem: SUB_SYSTEM_TYPES.FILE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.REMOVE_FILE,
            path: '<ex: /opt/amwell/config/app/DOMAIN>',
        },
    },
    LIST_FILES: {
        file: {
            subsystem: SUB_SYSTEM_TYPES.FILE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.LIST_FILES,
            path: '<ex: /opt/amwell/config/app>',
        },
    },
};

export const NETWORK = {
    ADD_DOMAIN_TO_DNS: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.ADD_DOMAIN_TO_DNS,
            domain: 'DOMAIN',
        },
    },
    GET_NETWORK_STATUS: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_NETWORK_STATUS,
        },
    },
    GET_NETWORK_LIST: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_LIST,
        },
    },
    RESOLVE_DOMAIN: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.RESOLVE_DOMAIN,
            timeout: 10, // 10s timeout value
            // domain: '<the domain the service attempted to resolve, e.g. amwell.com>',
            // success: '<true | false>',
            // message: '<any additional info pertaining to the resolve attempt>',
        },
        reserved: { component: '', pageURL: '', message: '' },
    },
    SET_LOCKED_BAND: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.SET_LOCKED_BAND,
            ssid: '<SSID OF NETWORK>',
            locked_band: '<BAND_AUTO | BAND_5G | BAND_2G>',
        },
    },
    SET_HOST_NAME: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.SET_HOSTNAME,
            hostname: undefined,
        },
    },
    GET_HOST_NAME: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_HOSTNAME,
        },
    },
    GET_NETWORK_CONFIG: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_NETWORK_CONFIG,
            ssid: '<SSID OF NETWORK (REQUIRED IF WIFI) | (FOR ETHERNET - LEAVE BLANK)>',
        },
    },
    GET_LOCKED_BAND: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_LOCKED_BAND,
            ssid: '<SSID OF NETWORK>',
        },
    },
    GET_DNS_SERVERS: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_DNS_SERVERS,
        },
    },
    DELETE_NETWORK: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.DELETE_NETWORK,
            ssid: 'ssid',
        },
    },
    ADD_SERVER_ARRAY_TO_DNS: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.ADD_SERVER_ARRAY_TO_DNS,
            server: '<SERVER TO ADD>', // expect array
        },
    },
    SET_ROAMING_THRESHOLD: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.SET_ROAMING_THRESHOLD,
            threshold: '< DEFAULT | HIGH | MEDIUM | LOW>',
        },
    },
    GET_ROAMING_THRESHOLD: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_ROAMING_THRESHOLD,
        },
    },
    DELETE_ALL_NETWORKS: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.DELETE_ALL_NETWORKS,
        },
    },
    NETWORK_DNS_UPDATE: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.DNS_UPDATE,
        },
    },
    CONNECT_NETWORK: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.CONNECT,
            ssid: '<SSID OF NETWORK>',
            username: '<OPTIONAL, USERNAME TO USE FOR AUTH>',
            password: '<OPTIONAL, PASSWORD TO USE FOR AUTH>',
            key_mgmt: '<OPTIONAL, DEFAULTS TO wpa-eap>',
            eap: '<OPTIONAL, DEFAULTS TO peap>',
            phase_2_auth: '<OPTIONAL, DEFAULTS to mschapv2',
            hidden: '<DEFAULTS to no>',
            locked_band: '<BAND_AUTO | BAND_5G | BAND_2G>',
            address_protocol: '<DHCP | STATIC ,DEFAULTS TO DHCP>',
            ipv4: {
                gateway: '<USER_INPUT>',
                address: '<USER_INPUT>',
                prefix: '<USER_INPUT>',
            },
            ipv6: {
                gateway: '<USER_INPUT>',
                address: '<USER_INPUT>',
                prefix: '<USER_INPUT>',
            },
        },
    },
    SET_IP_CONFIG: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.SET_IP_CONFIG,
            connection_type: '<WIFI | ETHERNET>',
            address_protocol: ' <DHCP | STATIC ,DEFAULTS TO DHCP',
            ssid: '<SSID OF NETWORK,REQUIRED IF WIFI,ELSE IGNORED>',
            ipv4: {
                gateway: '<USER_INPUT>',
                address: '<USER_INPUT>',
                prefix: '<USER_INPUT>',
            },
            ipv6: {
                gateway: '<USER_INPUT>',
                address: '<USER_INPUT>',
                prefix: '<USER_INPUT>',
            },
        },
    },
    ADD_SERVER_TO_DNS: {
        network: {
            subsystem: SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.ADD_SERVER_TO_DNS,
            server: '<SERVER TO ADD>', // expect single string e.g '1.1.1.1'
        },
    },
    DEFAULT_CONNECT_NETWORK_COMMAND_IPV4: {
        address: '',
        gateway: '',
        prefix: '',
    },
    DEFAULT_CONNECT_NETWORK_COMMAND_IPV6: {
        address: '',
        gateway: '',
        prefix: '',
    },
};

export const SPINE = {
    NERVE_GET_DETECTED: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.NERVE_GET_DETECTED,
            module: 'NERVE',
        },
    },
    RIPPLE_GET_AC_BATT_INFO: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.RIPPLE_GET_AC_BATT_INFO,
            module: 'NERVE',
        },
    },
    POLL_AUDIO_JACK_STATUS: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            module: 'SENSOR',
            command: SYSTEM_COMMANDS_TYPES.POLL_AUDIO_JACK_STATUS,
        },
    },
    GET_SERIAL_NUMBER: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            module: 'CONFIG',
            command: SYSTEM_COMMANDS_TYPES.GET_SERIAL_NUMBER,
        },
    },
    GET_FW_VERSION: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            module: 'SYSTEM',
            command: SYSTEM_COMMANDS_TYPES.GET_FW_VERSION,
        },
    },
    LED_WORKSURFACE_ON: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.WORKSURFACE_ON,
            module: 'LED',
        },
    },
    LED_WORKSURFACE_OFF: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.WORKSURFACE_OFF,
            module: 'LED',
        },
    },
    LED_WORKSURFACE_AUTO: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.WORKSURFACE_AUTO,
            module: 'LED',
        },
    },
    LED_RING_ON: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.RING_ON,
            module: 'LED',
        },
    },
    LED_RING_OFF: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.RING_OFF,
            module: 'LED',
        },
    },
    LED_RING_ANIMATION_REG_ERROR: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.RING_ANIMATION_REG_ERROR,
            module: 'LED',
        },
    },
    LED_RING_ANIMATION_NET_ERROR: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.RING_ANIMATION_NET_ERROR,
            module: 'LED',
        },
    },
    LED_RING_ANIMATION_STANDBY: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.RING_ANIMATION_STANDBY,
            module: 'LED',
        },
    },
    LED_RING_ANIMATION_LOW_BATTERY: {
        spine_comms: {
            subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.RING_ANIMATION_LOW_BATTERY,
            module: 'LED',
        },
    },
    AUDIO_CONNECTIONS_DATA: {
        subsystem: SUB_SYSTEM_TYPES.SPINE_MANAGER,
        command: SYSTEM_COMMANDS_TYPES.AUDIO_CONNECTIONS_DATA,
        connections: {},
    },
};

export const SYSTEM_INFO_MANAGER = {
    SYSTEM_INFO_COMMAND: {
        system_info: {
            subsystem: SUB_SYSTEM_TYPES.SYSTEM_INFO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_SYSTEM_INFO,
        },
    },
    FACTORY_DEFAULT_COMMAND: {
        system_info: {
            subsystem: SUB_SYSTEM_TYPES.SYSTEM_INFO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.FACTORY_DEFAULT,
        },
    },
    PROD_MODE: {
        system_info: {
            subsystem: SUB_SYSTEM_TYPES.SYSTEM_INFO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.CHANGE_MODE,
            mode: 'PROD',
        },
    },
    DEV_MODE: {
        system_info: {
            subsystem: SUB_SYSTEM_TYPES.SYSTEM_INFO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.CHANGE_MODE,
            mode: 'DEV',
        },
    },
};

export const AUDIO_MANAGER = {
    GET_AUDIO_CONTROLS: {
        audio: {
            subsystem: SUB_SYSTEM_TYPES.AUDIO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.AUDIO_GET_CONTROLS,
        },
    },
    AUDIO_MUTE_CONTROL: {
        audio: {
            subsystem: SUB_SYSTEM_TYPES.AUDIO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.AUDIO_MUTE_CONTROL,
            parameters: {
                card_name: '<card name>',
                ctrl_name: '<control name from get controls command>',
                device_type: '<Sink | Source>',
                mute: '<on | off | toggle>',
            },
        },
    },
    AUDIO_VOLUME_CONTROL_SOURCE: {
        audio: {
            subsystem: SUB_SYSTEM_TYPES.AUDIO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.AUDIO_CHANGE_CONTROL_VOLUME,
            parameters: {
                card_name: '<card name>',
                ctrl_name: '<control name from get controls command>',
                device_type: 'Source',
                volume: '<volume either as a percentage (0 - 100) or a raw value (101 - 65535)>',
            },
        },
    }, // New set of commands
    AUDIO_VOLUME_CONTROL_SINK: {
        audio: {
            subsystem: SUB_SYSTEM_TYPES.AUDIO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.AUDIO_CHANGE_CONTROL_VOLUME,
            parameters: {
                card_name: '<card name>',
                ctrl_name: '<control name from get controls command>',
                device_type: 'Sink',
                volume: '<volume either as a percentage (0 - 100) or a raw value (101 - 65535)>',
            },
        },
    },
    AUDIO_CHANGE_CONTROL_MAX_VOLUME: {
        audio: {
            subsystem: SUB_SYSTEM_TYPES.AUDIO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.AUDIO_CHANGE_CONTROL_MAX_VOLUME,
            parameters: {
                device_type: 'Sink',
                max_volume: '<max volume: a raw value (65536 - 200000)>',
            },
        },
    }, // New set of commands
    AUDIO_GET_CONTROLS: {
        audio: {
            subsystem: SUB_SYSTEM_TYPES.AUDIO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.AUDIO_GET_CONTROLS,
        },
    },
    AUDIO_ENABLE_ECHO_CANCEL: {
        audio: {
            subsystem: SUB_SYSTEM_TYPES.AUDIO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.AUDIO_CHANGE_CONTROL_ECHO_CANCEL,
            parameters: {
                on: true,
                aec: 'webrtc',
                source: null,
                sink: null,
            },
        },
    },
    AUDIO_SET_DEFAULT_SOURCE: {
        audio: {
            subsystem: SUB_SYSTEM_TYPES.AUDIO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.AUDIO_SET_DEFAULT_SOURCE,
            source: '<source ctrl_name from get controls command>',
        },
    },
    AUDIO_SET_DEFAULT_SINK: {
        audio: {
            subsystem: SUB_SYSTEM_TYPES.AUDIO_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.AUDIO_SET_DEFAULT_SINK,
            sink: '<source ctrl_name from get controls command>',
        },
    },
};

export const EMBRAVIA_LIGHTS = {
    GET_LED_STATUS: {
        peripherals: {
            subsystem: SUB_SYSTEM_TYPES.EMBRAVA_LIGHT,
            command: SYSTEM_COMMANDS_TYPES.GET_STATUS,
        },
    },
    LED_TURN_ON: {
        peripherals: {
            subsystem: SUB_SYSTEM_TYPES.EMBRAVA_LIGHT,
            command: SYSTEM_COMMANDS_TYPES.TURN_ON,
            color: 'COLOR',
        },
    },
    LED_TURN_OFF: {
        peripherals: {
            subsystem: SUB_SYSTEM_TYPES.EMBRAVA_LIGHT,
            command: SYSTEM_COMMANDS_TYPES.TURN_OFF,
        },
    },
    LED_TURN_BLUE: {
        peripherals: {
            subsystem: SUB_SYSTEM_TYPES.EMBRAVA_LIGHT,
            command: SYSTEM_COMMANDS_TYPES.TURN_ON,
            color: 'BLUE',
        },
    },
};

export const CRADLE_COMMS = {
    GET_CRADLESTATS_COMMAND: {
        peripherals: {
            subsystem: SUB_SYSTEM_TYPES.CRADLE_COMMS,
            command: SYSTEM_COMMANDS_TYPES.GET_STATUS,
        },
    },
    CRADLE_DEAD_BATTERY_SIGNAL: {
        peripherals: {
            subsystem: SUB_SYSTEM_TYPES.CRADLE_COMMS,
            command: SYSTEM_COMMANDS_TYPES.EVENT,
            attached: 'true',
            battery: {
                state: 'EMPTY',
                percent_charge: '1',
                error: 'true',
                error_msg:
                    'The cradle has given no power response since bootup.',
            },
        },
    },
};

export const TV_CONTROL = {
    SWITCH_TO_PRIMARY_SOURCE: {
        peripherals: {
            subsystem: SUB_SYSTEM_TYPES.TV_CONTROL,
            command: SYSTEM_COMMANDS_TYPES.SWITCH_TO_PRIMARY_SOURCE,
        },
    },
    RETURN_TO_PREVIOUS_SOURCE: {
        peripherals: {
            subsystem: SUB_SYSTEM_TYPES.TV_CONTROL,
            command: SYSTEM_COMMANDS_TYPES.RETURN_TO_PREVIOUS_SOURCE,
        },
    },
    GET_TV_STATUS: {
        peripherals: {
            subsystem: SUB_SYSTEM_TYPES.TV_CONTROL,
            command: SYSTEM_COMMANDS_TYPES.GET_STATUS,
        },
    },
    TV_TURN_ON: {
        peripherals: {
            subsystem: SUB_SYSTEM_TYPES.TV_CONTROL,
            command: SYSTEM_COMMANDS_TYPES.TURN_ON,
        },
    },
    TV_TURN_OFF: {
        peripherals: {
            subsystem: SUB_SYSTEM_TYPES.TV_CONTROL,
            command: SYSTEM_COMMANDS_TYPES.TURN_OFF,
        },
    },
    TV_SET_PRIMARY_SOURCE: {
        peripherals: {
            subsystem: SUB_SYSTEM_TYPES.TV_CONTROL,
            command: SYSTEM_COMMANDS_TYPES.SET_PRIMARY_SOURCE,
            source: 'hdmi1', // < 'aux' | 'hdmi1' | 'hdmi2'>
        },
    },
};

export const POWER_MANAGER = {
    GET_POWER_STATS: {
        power: {
            subsystem: SUB_SYSTEM_TYPES.POWER_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_POWER_STATS,
        },
    },
    DISPLAY_TURN_ON: {
        power: {
            subsystem: SUB_SYSTEM_TYPES.POWER_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.DISPLAY,
            state: 'ON',
        },
    },
    DISPLAY_TURN_OFF: {
        power: {
            subsystem: SUB_SYSTEM_TYPES.POWER_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.DISPLAY,
            state: 'OFF',
        },
    },
    REBOOT: {
        power: {
            subsystem: SUB_SYSTEM_TYPES.POWER_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.REBOOT,
        },
    },
    SHUTDOWN: {
        power: {
            subsystem: SUB_SYSTEM_TYPES.POWER_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.SHUTDOWN,
        },
    },
};

export const USB_MANAGER = {
    GET_LIST: {
        usb: {
            subsystem: SUB_SYSTEM_TYPES.USB_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_LIST,
        },
    },
    REENUMERATE_DEVICE: {
        usb: {
            subsystem: SUB_SYSTEM_TYPES.USB_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.REENUMERATE_DEVICE,
            product: "<Product's name>",
            pid: 'Hex Product\'s ID. Ex "0x2137',
            vid: "<Hex Vendor's ID. Ex 0x0424>",
        },
    },
};

export const UPDATER = {
    ROLLBACK_APP: {
        updater: {
            subsystem: SUB_SYSTEM_TYPES.APPLICATION,
            command: SYSTEM_COMMANDS_TYPES.ROLLBACK,
        },
    },
    ROLLBACK_OS: {
        updater: {
            subsystem: SUB_SYSTEM_TYPES.OPERATING_SYSTEM,
            command: SYSTEM_COMMANDS_TYPES.ROLLBACK,
        },
    },
    UPDATE_APP: {
        updater: {
            subsystem: SUB_SYSTEM_TYPES.APPLICATION,
            command: SYSTEM_COMMANDS_TYPES.UPDATE,
            url: 'APP_URL',
            hash_url: 'HASH_URL',
            // patch_url: 'www.s3.amazonaws.com/mobile1builds/<apgar-os | apgar-codec>/<version number>/patch/<old version number>/patch.gz',
            // patch_hash_url: 'www.s3.amazonaws.com/mobile1builds/<apgar-os | apgar-codec>/<version number>/patch/<old version number>/hash.sha256',
            // force: '<true | false>'
        },
    },
    UPDATE_OS: {
        updater: {
            subsystem: SUB_SYSTEM_TYPES.OPERATING_SYSTEM,
            command: SYSTEM_COMMANDS_TYPES.UPDATE,
            url: 'OS_URL',
            hash_url: 'HASH_URL',
            // patch_url: 'www.s3.amazonaws.com/mobile1builds/<apgar-os | apgar-codec>/<version number>/patch/<old version number>/patch.gz',
            // patch_hash_url: 'www.s3.amazonaws.com/mobile1builds/<apgar-os | apgar-codec>/<version number>/patch/<old version number>/hash.sha256',
            // force: '<true | false>'
        },
    },
};

export const CAMERA_MANAGER = {
    GET_CAMERA_LIST: {
        video: {
            subsystem: SUB_SYSTEM_TYPES.CAMERA_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_CAMERA_LIST,
        },
    },

    GET_CAMERA_CAPABILITIES: {
        video: {
            subsystem: SUB_SYSTEM_TYPES.CAMERA_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_CAMERA_CAPABILITIES,
            path: '< /dev/videoX >',
        },
    },
    GET_CAMERA_CURRENT_POSITION: {
        video: {
            subsystem: SUB_SYSTEM_TYPES.CAMERA_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_CAMERA_CURRENT_POSITION,
            path: '< /dev/videoX >',
        },
    },

    GET_CAMERA_PARAM: {
        video: {
            subsystem: SUB_SYSTEM_TYPES.CAMERA_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.GET_CAMERA_PARAM,
            path: '< /dev/videoX >',
            param_name: '< brightness >',
        },
    },

    SET_CAMERA_PARAM: {
        video: {
            subsystem: SUB_SYSTEM_TYPES.CAMERA_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.SET_CAMERA_PARAM,
            path: '< /dev/videoX >',
            param_name: '< brightness >',
            param_value: '< 14 >',
        },
    },

    CAMERA_PTZ: {
        video: {
            subsystem: SUB_SYSTEM_TYPES.CAMERA_MANAGER,
            command: SYSTEM_COMMANDS_TYPES.CAMERA_PTZ,
            path: '< /dev/videoX >',
            ptz_values: {
                pan: '< arcsecond >',
                tilt: '< arcsecond >',
                zoom: '< 4 >',
            },
        },
    },
};
