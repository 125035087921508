import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { timer } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { updateBatteryStatus } from './battery.actions';
import { IBatteryStatus } from '@/shared/interfaces/battery/battery.interface';
import { IpcService, SessionService } from '@shared/services';
import { BatteryAlerts } from '@/shared/enums/battery-alerts.enum';
import {
    NONE_BATTERY_SHUTDOWN_TIMEOUT,
    NONE_BATTERY_TONE,
} from '@/shared/constants/device';
import { BatteryLevels } from '@/shared/enums/battery-levels.enum';
import { IHTMLAudio } from '@/shared/interfaces/common/html-audio.interface';
import {
    BatterAlertLow,
    BatteryAlertCritical,
    BatteryAlertNone,
} from '@/shared/constants/battery-alert';
import { ILowBatteryModalData } from '@/shared/interfaces/battery/low-battery-modal-data.interface';
import { BatteryStates } from '@/shared/enums/battery-states.enum';
import { LowBatteryModalComponent } from '@/core/components/low-battery-modal/low-battery-modal.component';
import { IActiveModal } from '../layout/layout.reducer';
import { openModal } from '../layout/layout.actions';

@Injectable()
export class BatteryEffects {
    private _lastBatteryAlert: BatteryAlerts;
    private _batteryTone: IHTMLAudio = new Audio(NONE_BATTERY_TONE);
    private _dialogRef: MatDialogRef<LowBatteryModalComponent>;
    public battery: IBatteryStatus;

    constructor(
        private _action$: Actions,
        private _store: Store,
        private _ipcService: IpcService,
        private _sessionService: SessionService
    ) {
        this.playBatteryToneInterval();
    }

    updateBatteryStatus$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(updateBatteryStatus),
                tap(({ batteryStatus }) => {
                    this.battery = batteryStatus;
                    if (this.battery) {
                        this.handleBatteryAlerts();
                    }
                })
            ),
        { dispatch: false }
    );

    handleBatteryAlerts(): void {
        const { acPowerConnected, batteryConnected, level } = this.battery;
        if (!acPowerConnected) {
            if (!!batteryConnected && this.displayBatteryPopupModal(level)) {
                const shutdownTimer = () => {
                    if (!acPowerConnected && level <= BatteryLevels.None) {
                        this._ipcService.shutdownDevice();
                    } else {
                        this.closeModal();
                    }
                };
                timer(NONE_BATTERY_SHUTDOWN_TIMEOUT).subscribe(shutdownTimer);
            }
        } else {
            this.closeModal();
            this._lastBatteryAlert = null;
            this.stopTone();
        }
    }

    displayBatteryPopupModal(level: number): boolean {
        let currentBatteryAlert: BatteryAlerts;
        let data: ILowBatteryModalData;

        if (level <= BatteryLevels.None) {
            currentBatteryAlert = BatteryAlerts.None;
            data = BatteryAlertNone;
        } else if (level <= BatteryLevels.Critical) {
            currentBatteryAlert = BatteryAlerts.Critical;
            data = BatteryAlertCritical;
        } else if (level <= BatteryLevels.Low) {
            currentBatteryAlert = BatteryAlerts.Low;
            data = BatterAlertLow;
        } else {
            this._lastBatteryAlert = null;
            this.closeModal();
            return false;
        }

        if (this._lastBatteryAlert !== currentBatteryAlert) {
            this._lastBatteryAlert = currentBatteryAlert;

            this.playTone(level <= BatteryLevels.Critical);

            if (level <= BatteryLevels.Critical) {
                this._ipcService.requestChangeLedRingColor(BatteryStates.Low);
            }

            this.closeModal();
            this.openModal(data);
            return currentBatteryAlert === BatteryAlerts.None;
        }

        return false;
    }

    closeModal(): void {
        if (this._dialogRef) {
            this._dialogRef.close();
        }
    }

    openModal(data: ILowBatteryModalData) {
        this.closeModal();
        const modal = {
            componentName: LowBatteryModalComponent.dialogName,
            config: {
                panelClass: ['aw-modal', 'modal-sm'],
                disableClose: true,
                data,
            },
            getDialogRef: (dialogRef: MatDialogRef<any>) => {
                this._dialogRef = dialogRef;
                this._dialogRef.componentInstance.closeClicked.subscribe(() => {
                    this.stopTone();
                });
            },
        } as IActiveModal;

        this._store.dispatch(openModal({ modal }));
    }

    playBatteryToneInterval(counter = 1): void {
        let nextTone = counter;
        const { acPowerConnected, level } = this.battery || {};

        if (
            this._batteryTone &&
            this.battery &&
            !acPowerConnected &&
            level <= BatteryLevels.Critical
        ) {
            if (counter === 0) {
                // Tone plays every 4 minutes, with increading frequency the lower the battery percentage.
                this.playTone(level <= BatteryLevels.Critical);
                this._ipcService.requestChangeLedRingColor(BatteryStates.Low);
            }

            nextTone = nextTone >= 8 ? 0 : nextTone + 11 - level;
        }

        timer(30000).subscribe(() => {
            this.playBatteryToneInterval(nextTone);
        });
    }
    /* istanbul ignore next */
    playTone(loop = false): void {
        this._sessionService.deviceInstance.setCriticalBatteryAlertSpeakerGain();
        this._batteryTone.loop = loop;
        this._batteryTone.play();
    }
    stopTone(): void {
        this._sessionService.deviceInstance.setSpeakerGain({
            volume: this._sessionService.getConfig().AudioOutputDefaultVolume,
        });
        this._batteryTone.pause();
        this._batteryTone.currentTime = 0;
        this._batteryTone.loop = false;
    }
}
