import { StatisticCollector } from '@/shared/services/statistic-collector/statistic-collector.service';
import {
    setPtzFeccPositionHome,
    setPtzFeccPositionPrivacy,
} from '@/shared/storage';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DEFAULT_OPTIONS, MESSAGES } from '@shared/constants';
import {
    ExtendedNavigator,
    IDevice,
    IEndpointConfiguration,
} from '@shared/interfaces';
import {
    MediaDevicesService,
    SessionService,
    StorageService,
} from '@shared/services';
import { combineLatest, Observable, of, Subscription, timer } from 'rxjs';

interface ElementVideo extends Element {
    src: string;
    srcObject?: any;
}
@Component({
    selector: 'app-audio-video-tab',
    templateUrl: './audio-video-tab.component.html',
})
export class AudioVideoTabComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    public config: IEndpointConfiguration;
    public cameraMessage: { text: string; icon: string };
    public microphoneMessage: { text: string; icon: string };
    public speakerMessage: { text: string; icon: string };
    public cameraOptions = [];
    public cameraModel = '';
    public cameraId = null;
    public microphoneOptions = [];
    public microphoneModel = '';
    public speakerModel = '';
    public speakerOptions = [];
    public microphoneId = null;
    public speakerId = null;
    public isSpeakerChecked = false;
    public initialSpeakerVolumeLevel = 0;
    public speakerVolumeLevel = 0;
    public initialRingtoneVolumeLevel = 0;
    public audioSrc = 'assets/audio/sound-test.m4a';
    public playing = false;
    public speakerPosition = 0;
    public duration = 1;

    private _subscriptions: Subscription[] = [];
    private _videoStream: MediaStream;
    private _selectedSpeaker: IDevice;
    private _navigator: ExtendedNavigator = navigator as ExtendedNavigator;
    // private _activatedTabInterval: Observable<number> = interval(500);

    @ViewChild('audioElement', { static: true })
    private _audionElementRef!: ElementRef;

    getMedia = (): void => {
        let getMedia = (params, success, error) => {
            /* istanbul ignore next */
            this._navigator.mediaDevices
                .getUserMedia(params)
                .then((stream) => {
                    if (success) {
                        success(stream);
                    }
                })
                .catch((err) => {
                    if (error) {
                        error(err);
                    }
                });
        };
        if (
            this._navigator.mediaDevices &&
            this._navigator.mediaDevices.getUserMedia
        ) {
            this._navigator = {
                ...this._navigator,
                ...getMedia,
            };
        } else {
            getMedia = this._navigator.getUserMedia;
            this._navigator = {
                ...this._navigator,
                ...getMedia,
            };
        }
    };

    constructor(
        private _sessionService: SessionService,
        private _mediaDeviceService: MediaDevicesService,
        private _storageService: StorageService,
        private _statisticCollector: StatisticCollector,
        private _store: Store
    ) {
        this._storageService.refreshEndpointConfig();
    }

    ngOnInit(): void {
        this.getMedia();

        this._navigator.getMedia({
            audio: true,
            video: true,
        });

        const unSubscriptions = combineLatest([
            this._mediaDeviceService.devicesPromise,
            this._storageService.subscribeOnConfigChange(
                false
            ) as Observable<any>,
        ]).subscribe(() => {
            this.config = this._sessionService.getConfig();
            const {
                AudioOutputDefaultVolume = 50,
                RingtoneDefaultVolume = 20,
            } = this.config || {};

            this.speakerVolumeLevel = AudioOutputDefaultVolume;
            this.initialSpeakerVolumeLevel = AudioOutputDefaultVolume;
            this.initialRingtoneVolumeLevel = RingtoneDefaultVolume;

            this.onDeviceChange();
            const selectedMicrophone =
                this._mediaDeviceService.defaultAudioInput();
            if (selectedMicrophone) {
                this.microphoneModel = selectedMicrophone.name;
                this.microphoneId = selectedMicrophone.deviceId;
                this.microphoneMessage = MESSAGES.success;
            }

            const selectedSpeaker = (this._selectedSpeaker =
                this._mediaDeviceService.defaultAudioOutput());
            if (selectedSpeaker) {
                this.speakerModel = selectedSpeaker.name;
                this.speakerId = selectedSpeaker.deviceId;
                this.speakerMessage = MESSAGES.success;

                if (selectedSpeaker.allowGain) {
                    const audioDeviceData = {
                        volume: this.speakerVolumeLevel,
                        vendorId: selectedSpeaker.vendorId,
                        productId: selectedSpeaker.productId,
                    };
                    this._sessionService.deviceInstance.setSpeakerGain(
                        audioDeviceData
                    );
                }

                //set sinkId to play Test audio
                const audioElement = this._audionElementRef?.nativeElement;
                !!audioElement && audioElement.setSinkId(this.speakerId);
            }
            const selectedCamera = this._mediaDeviceService.defaultVideoInput();
            if (selectedCamera && selectedCamera.deviceId !== this.cameraId) {
                this.cameraModel = (selectedCamera as IDevice).name;
                this.cameraId = selectedCamera.deviceId;
                if (this.isMinrrayCameraListedAsUSB2(selectedCamera)) {
                    this.cameraMessage =
                        MESSAGES.looselyConnectedMinrrayCameraError;
                } else {
                    this.cameraMessage = MESSAGES.success;
                }
                this.onCameraPreview(selectedCamera.deviceId as string);
            } else {
                this.cameraModel = (selectedCamera as IDevice).name;
            }
        });
        this._subscriptions.push(unSubscriptions);
        this._store.dispatch(setPtzFeccPositionHome());

        this.initSpeakerChecker();
    }

    ngAfterViewInit(): void {
        // fix to render <aw-sound-meter> properly
        // due to css-flex and js-html-made-component issue
        timer(10).subscribe(() => {
            window.dispatchEvent(new Event('resize'));
        });
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(
            (subscription: Subscription) =>
                subscription && subscription.unsubscribe()
        );
        if (this._videoStream) {
            this.closeStream(this._videoStream);
        }

        this._store.dispatch(setPtzFeccPositionPrivacy());
    }

    isMinrrayCameraListedAsUSB2(selectedCamera): boolean {
        return (
            ((selectedCamera as IDevice).vendorId === '0x0408' &&
                ((selectedCamera as IDevice).productId === '0x2070' ||
                    (selectedCamera as IDevice).productId === '0x7020')) ||
            ((selectedCamera as IDevice).vendorId === '0x04b4' &&
                (selectedCamera as IDevice).productId === '0x04f2')
        );
    }

    getDevices(type): Array<IDevice> {
        switch (type) {
            case 'camera':
                return this._mediaDeviceService.getCameraInput();
            case 'microphone':
                return this._mediaDeviceService.audioInput();
            case 'speaker':
                return this._mediaDeviceService.audioOutput();
        }
    }

    setSelectOptions(type: string, options: Array<any> = []): void {
        if (!options.length) {
            return;
        }

        this[type + 'Model'] = options[0].name;
        this[type + 'Options'] = options.map((opt) => {
            const option = { ...opt };
            option.value = option.name ? option.name : option.label;
            option.view = option.value;
            return option;
        });
    }

    getOptions(
        type: string
    ): Observable<Array<{ id: string; name: string; deviceId: string }>> {
        const devices = this.getDevices(type);
        const options = [];

        devices.forEach((device) => {
            if (!device.label) {
                return;
            }

            if (!options.length) {
                options.push(DEFAULT_OPTIONS[type]);
            }

            options.push({
                id: device.name ? device.name : device.label,
                name: device.name ? device.name : device.label,
                deviceId: device.label,
            });
        });

        return of(options);
    }

    onStartTesting(type: string): void {
        this[type + 'Message'] = null;

        this._subscriptions.push(
            this.getOptions(type).subscribe((options = []) => {
                if (options.length) {
                    this.setSelectOptions(type, options);
                    return;
                }
                this.getDevicesAccess(type);
            })
        );
    }

    getDevicesAccess(type: string): void {
        let params: { [key: string]: { deviceId: string } } = {
            video: {
                deviceId: undefined,
            },
        };

        if (type === 'microphone' || type === 'speaker') {
            params = {
                audio: {
                    deviceId: undefined,
                },
            };
        }

        /* istanbul ignore next */
        this._navigator.getMedia(
            params,
            (stream: MediaStream) => {
                this._subscriptions.push(
                    this.getOptions(type).subscribe((options = []) => {
                        this.closeStream(stream);
                        if (options.length) {
                            this.setSelectOptions(type, options);
                        } else {
                            if (type === 'speaker') {
                                this.isSpeakerChecked = true;
                                this[`${type}Message`] =
                                    MESSAGES[`${type}Warning`];
                            } else {
                                this[`${type}Message`] =
                                    MESSAGES[`${type}Error`];
                            }
                        }
                    })
                );
            },
            () => {
                this[`${type}Message`] = MESSAGES.error;
            }
        );
    }

    closeStream(stream: MediaStream): null {
        stream.getTracks().forEach((track) => {
            track.stop();
        });

        return null;
    }

    onChangeCamera(deviceName: string): void {
        const item = this.cameraOptions.find(
            (camera) => camera.name === deviceName
        );
        if (this.cameraId == item?.deviceId) {
            return;
        }
        if (item.isFeccEnabled) {
            this._store.dispatch(setPtzFeccPositionPrivacy());
            this._storageService.updateDeviceInfoByKey(
                'codecVersion',
                `${item.vendorId}_${item.productId}`
            );
            const state = {
                productId: item.productId,
                vendorId: item.vendorId,
                label: item.label,
            };
            this._sessionService.deviceInstance.changeCodecVersion(state);
        }

        this.cameraId = item.deviceId;

        if (this.isMinrrayCameraListedAsUSB2(item)) {
            this.cameraMessage = MESSAGES.looselyConnectedMinrrayCameraError;
        } else {
            this.cameraMessage = MESSAGES.success;
        }

        this.updateSettings('DefaultVideoInput', false, true);
        this.updateSettings('VideoInput', item.label);

        setTimeout(() => {
            this.onCameraPreview(item.deviceId);
        }, 100);
        this._statisticCollector.saveEvent(
            'Settings_Audio/Video',
            'Select Video input',
            `Select Video input via dropdown menu item ${item.name}_Settings modal dialog`
        );
    }

    onChangeMicrophone(deviceName: string): void {
        const item = this.microphoneOptions.find(
            (mic) => mic.name === deviceName
        );

        if (!item || !item.deviceId) {
            return;
        }
        this.microphoneId = item.deviceId;
        this.microphoneMessage = MESSAGES.success;
        this.updateSettings('DefaultAudioInput', false, true);
        this.updateSettings('AudioInput', item.label);
        this._statisticCollector.saveEvent(
            'Settings_Audio/Video',
            'Select Audio input',
            `Select Audio input via dropdown menu item ${item.name}_Settings modal dialog`
        );
    }

    onChangeSpeaker(deviceName: string): void {
        const item = this.speakerOptions.find(
            (speaker) => speaker.name === deviceName
        );
        if (!item || !item.deviceId) {
            return;
        }

        const audioDeviceData = {
            volume: this.speakerVolumeLevel,
            vendorId: item.vendorId,
            productId: item.productId,
        };

        this._sessionService.deviceInstance.setSpeakerGain(audioDeviceData);

        this.speakerId = item.deviceId;
        this.speakerMessage = MESSAGES.success;
        this.updateSettings('DefaultAudioOutput', false, true);
        this.updateSettings('AudioOutput', item.label);
        this._statisticCollector.saveEvent(
            'Settings_Audio/Video',
            'Select Audio output',
            `Select Audio output via dropdown menu item ${item.name}_Settings modal dialog`
        );
    }

    updateSettings(
        key: string,
        value: string | number | boolean,
        skipSettings = false
    ): void {
        this._storageService.updateEndpointConfig(key, value, skipSettings);
    }

    onChangeSpeakerVolume(volumeLevel: number): void {
        volumeLevel = Math.ceil(volumeLevel);
        this.speakerVolumeLevel = volumeLevel;
        if (this._selectedSpeaker.allowGain) {
            const audioDeviceData = {
                volume: volumeLevel,
                vendorId: this._selectedSpeaker.vendorId,
                productId: this._selectedSpeaker.productId,
            };
            this._sessionService.deviceInstance.setSpeakerGain(audioDeviceData);
        }
        this.updateSettings('AudioOutputDefaultVolume', volumeLevel);
        this._statisticCollector.saveEvent(
            'Settings_Audio/Video',
            'Change default volume',
            'Change default volume via slider Adjust the default volume_Settings modal dialog'
        );
    }

    onUpdateSpeakerVolume(volumeLevel: number): void {
        this._audionElementRef.nativeElement.volume = volumeLevel / 100;
    }

    onCameraPreview(currentCamera: string): void {
        if (this._videoStream) {
            this.closeStream(this._videoStream);
        }
        const video = {
            deviceId: {
                exact: currentCamera,
            },
        };

        this._navigator.getMedia(
            {
                audio: false,
                video,
            },
            (stream: MediaStream) => {
                const preview = document.querySelector(
                    '#preview'
                ) as ElementVideo;

                if ('srcObject' in preview) {
                    preview.srcObject = stream;
                } else {
                    preview.src = window.URL.createObjectURL(stream);
                }

                this._videoStream = stream;
            },
            () => {
                this.cameraMessage = MESSAGES.error;
            }
        );
    }

    onDeviceChange(): void {
        this.setSelectOptions(
            'camera',
            this._mediaDeviceService.getCameraInput()
        );
        this.setSelectOptions(
            'microphone',
            this._mediaDeviceService.audioInput()
        );
        this.setSelectOptions(
            'speaker',
            this._mediaDeviceService.audioOutput()
        );
    }

    onChangeRingtoneVolume(volumeLevel: number): void {
        volumeLevel = Math.ceil(volumeLevel);
        this.updateSettings('RingtoneDefaultVolume', volumeLevel);
    }

    initSpeakerChecker(): void {
        const audioElement = this._audionElementRef.nativeElement;
        const duration = document.querySelector('#duration');
        const calculateTime = (secs) => {
            const minutes = Math.floor(secs / 60);
            const seconds = Math.floor(secs % 60);
            const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
            return `${minutes}:${returnedSeconds}`;
        };
        audioElement.src = this.audioSrc;
        audioElement.volume = this.initialSpeakerVolumeLevel / 100;
        audioElement.addEventListener('loadedmetadata', () => {
            duration.textContent = calculateTime(audioElement.duration);
            this.duration = audioElement.duration;
        });
    }

    handleStop(): void {
        if (!this._canPlay()) {
            return;
        }
        const audioElement = this._audionElementRef.nativeElement;
        audioElement.pause();
        this.playing = false;
    }

    handlePlay(): void {
        if (!this._canPlay()) {
            return;
        }
        const audioElement = this._audionElementRef.nativeElement;
        audioElement.setSinkId(this.speakerId);
        const currentTime = document.querySelector('#current-time');
        const calculateTime = (secs) => {
            const minutes = Math.floor(secs / 60);
            const seconds = Math.floor(secs % 60);
            const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
            return `${minutes}:${returnedSeconds}`;
        };

        audioElement.play();
        audioElement.addEventListener('timeupdate', () => {
            const time = calculateTime(audioElement.currentTime);
            currentTime.textContent = time;
            this.speakerPosition = Math.floor(audioElement.currentTime);
        });
        this.playing = true;
        this._statisticCollector.saveEvent(
            'Settings_Audio/Video',
            'Play test sound',
            'Play test sound via button Play_Settings modal dialog'
        );
    }

    onChangeAudioTime(time: number): void {
        const audioElement = this._audionElementRef.nativeElement;
        audioElement.currentTime = time;
    }

    private _canPlay(): boolean {
        return (
            this.audioSrc &&
            this._audionElementRef &&
            this._audionElementRef.nativeElement
        );
    }
}
