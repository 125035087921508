import { IServiceLine } from '@/shared/interfaces';
import { CaseService } from '@/shared/services';
import { TrackEventDataKeys } from '@/shared/services/statistic-collector/static-collector-defined-data';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from '@aw-hospital/aw-components-lib/src/services/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LEAVE_CASE_OPEN_QUEUE, MAX_SNACKBARS } from '@shared/constants';
import * as fromStore from '@shared/storage';
import { IActiveModal } from '@shared/storage';
import { of } from 'rxjs';
import {
    filter,
    first,
    map,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { StatisticCollector } from '@shared/services';
import { LoggerService } from '@services/logger';

@Injectable()
export class CaseEffects {
    constructor(
        private _actions$: Actions,
        private _store: Store,
        private _caseService: CaseService,
        private _router: Router,
        private _snackbarService: SnackbarService,
        private _statisticCollector: StatisticCollector,
        private _loggerService: LoggerService
    ) {
        this._snackbarService.setMaxVisible(
            MAX_SNACKBARS,
            LEAVE_CASE_OPEN_QUEUE
        );
    }

    createCase$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromStore.createCase),
            tap(() =>
                this._router
                    .navigateByUrl('waiting-room')
                    .then((response: boolean) => {
                        this._loggerService.log('CREATE_CASE_EFFECT', {
                            details: {
                                waitingRoomRedirectSuccess: response,
                            },
                        });
                    })
            ),
            switchMap(({ intakeData }: { intakeData: IServiceLine }) =>
                this._caseService.createCase(intakeData)
            ),
            switchMap(({ case_id }) =>
                of(fromStore.setCaseId({ caseId: case_id }))
            )
        )
    );

    confirmCancelCase$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromStore.confirmCancelCase),
            switchMap(() =>
                this._store.select(fromStore.selectServiceLine).pipe(first())
            ),
            switchMap(({ name }: IServiceLine) =>
                of(
                    fromStore.openModal({
                        modal: this._caseService
                            .getConfirmCancelCaseModal(name)
                            .setProp(
                                'afterClosed',
                                this._afterCancelCaseModalClosed.bind(this)
                            ) as IActiveModal,
                    })
                )
            ),
            tap(() => {
                this._statisticCollector.trackEvent(
                    TrackEventDataKeys.InitiateCancelCase
                );
            })
        )
    );

    cancelCase$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(fromStore.cancelCase),
                switchMap(() =>
                    this._store
                        .select(fromStore.selectServiceLine)
                        .pipe(first())
                ),
                // TODO it's not clear do we need this just before canceling the case
                map((serviceLine: IServiceLine) => ({
                    ...serviceLine,
                    is_cancelling: true,
                })),
                tap((serviceLine: IServiceLine) =>
                    this._store.dispatch(
                        fromStore.updateServiceLine({ serviceLine })
                    )
                ),
                withLatestFrom(this._store.select(fromStore.selectCaseId)),
                filter(([serviceLine, caseId]) => serviceLine && caseId),
                switchMap(([serviceLine, caseId]) =>
                    this._caseService.cancelCase(
                        caseId,
                        serviceLine.workflow_id
                    )
                )
            ),
        { dispatch: false }
    );

    discardCancelCase$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(fromStore.discardCancelCase),
                tap(() => {
                    this._statisticCollector.trackEvent(
                        TrackEventDataKeys.DiscardCase
                    );
                }),
                switchMap(() =>
                    this._store
                        .select(fromStore.selectServiceLine)
                        .pipe(first())
                ),
                filter((serviceLine: IServiceLine) => serviceLine.is_transfer),
                switchMap(() =>
                    of(this._router.navigateByUrl('waiting-room-next'))
                )
            ),
        { dispatch: false }
    );

    confirmLeaveCaseOpen$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromStore.confirmLeaveCaseOpen),
            switchMap(() =>
                of(
                    fromStore.openModal({
                        modal: this._caseService
                            .getConfirmLeaveCaseOpenModal()
                            .setProp(
                                'afterClosed',
                                this._afterLeaveCaseOpenModalClosed.bind(this)
                            ) as IActiveModal,
                    })
                )
            )
        )
    );

    leaveCaseOpen$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(fromStore.leaveCaseOpen),
                tap(() => this._store.dispatch(fromStore.navigateToMain())),
                // TODO add analytics call side effect to save event?
                tap(() =>
                    this._snackbarService.show(
                        this._caseService.getLeaveCaseOpenSuccessSnackbar()
                    )
                )
            ),
        { dispatch: false }
    );

    private _afterCancelCaseModalClosed(confirmed: boolean): void {
        if (!confirmed) {
            this._store.dispatch(fromStore.discardCancelCase());
            return;
        }

        this._store.dispatch(fromStore.navigateToMain());
        this._store.dispatch(fromStore.cancelCase());
        this._statisticCollector.trackEvent(TrackEventDataKeys.CancelCase);
    }

    private _afterLeaveCaseOpenModalClosed(confirmed: boolean): void {
        if (!confirmed) {
            return;
        }

        this._store.dispatch(fromStore.leaveCaseOpen());
    }
}
