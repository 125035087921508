import { FeccPosition } from '@/shared/enums';
import { PTZService } from '@/shared/services';
import * as fromSelectors from '@/shared/storage/selectors';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    filter,
    first,
    map,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { selectIsOpenSettingModal } from './../selectors/layout.selector';
import * as fromActions from './ptz.actions';

@Injectable({ providedIn: 'root' })
export class PtzEffects {
    private _newFeccDeviceAttached = false;

    constructor(
        private _actions$: Actions,
        private _store: Store,
        private _ptzService: PTZService
    ) {}

    setPtzFeccPosition$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                fromActions.setPtzFeccPositionHome,
                fromActions.setPtzFeccPositionPrivacy
            ),
            withLatestFrom(
                this._store.select(fromSelectors.selectPtzFeccPosition),
                this._store.select(fromSelectors.selectCameraPrivacyMode)
            ),
            map(([{ position, force }, currentPosition, cameraPrivacyMode]) => {
                let nextPosition = position;

                if (!cameraPrivacyMode) {
                    nextPosition = FeccPosition.Home;
                }

                const updateRequired =
                    force || currentPosition !== nextPosition;

                if (!updateRequired) {
                    return null;
                }

                return nextPosition;
            }),
            filter((nextPosition) => !!nextPosition),
            tap((position) => this._ptzService.setFeccPosition(position)),
            map((position) =>
                fromActions.setPtzFeccPositionSuccess({ position })
            )
        )
    );

    newFeccDeviceAttached$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(fromActions.newFeccDeviceAttached),
                tap(() => (this._newFeccDeviceAttached = true))
            ),
        { dispatch: false }
    );

    cameraChanged$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(fromActions.cameraChanged),
                switchMap(() =>
                    this._store.select(selectIsOpenSettingModal).pipe(first())
                ),
                map((isOpenSettingModal) => {
                    if (isOpenSettingModal) {
                        return fromActions.setPtzFeccPositionHome(true);
                    }

                    if (this._newFeccDeviceAttached) {
                        this._newFeccDeviceAttached = false;
                        return fromActions.setPtzFeccPositionPrivacy(true);
                    }
                }),
                filter((ptzFeccPosition) => !!ptzFeccPosition),
                tap((ptzFeccPosition) => this._store.dispatch(ptzFeccPosition))
            ),
        { dispatch: false }
    );
}
