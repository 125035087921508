<div class="flex h100 flex-column gradient-diagonal-blue gradient transition-off rel overflow-hidden lost-wrap">
    <div
        class="flex nowrap flex-1 flex-column items-stretch overflow-auto lost-connection-screen"
        coreGlobalKeyboardPadding
    >
        <div class="center-align on-gradient">
            <h2 class="white-text">No Network</h2>
            <h3 class="white-text m-b3">Check your connection and try again</h3>
        </div>
        <div class="row no-network-connectivity white">
            <app-connection-settings></app-connection-settings>
        </div>
        <div class="footer-stick">
            <aw-button
                automationValue="restartButton"
                elementId="restartButton"
                styleClasses="btn-flat waves-effect waves-light white-text btn-large modal-trigger"
                (onClick)="restartDevice()"
            >
                <i class="material-icons">refresh</i>
                Restart
            </aw-button>
            <aw-button
                automationValue="turnOffButton"
                elementId="turnOffButton"
                styleClasses="btn-flat waves-effect waves-light white-text btn-large modal-trigger"
                (onClick)="shutdownDevice()"
            >
                <i class="material-icons">power_settings_new</i>
                Turn off
            </aw-button>
            <aw-button
                automationValue="returnButton"
                elementId="returnButton"
                styleClasses="btn btn-simple waves-effect waves-light btn-large right"
                [disabled]="!isOnline"
                (onClick)="returnToHomepage()"
            >
                Return to Dashboard
            </aw-button>
        </div>
    </div>
</div>
