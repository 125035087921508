import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@/shared/shared.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { WebsocketModule } from './websocket';
import { CoreModule } from './core';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';

import {
    EndpointConfigEffects,
    MediaDevicesEffects,
    AuthEffects,
    LayoutEffects,
    BatteryEffects,
    CaseEffects,
    PtzEffects,
} from './shared/storage';

import * as fromAppReducer from './app.reducer';

import { AppInitService } from './shared/services/app-init/app-init.service';
import {
    EndPointService,
    FleetHandlerService,
    IpcResponseService,
    IpcService,
    SessionService,
    StorageService,
    ActivityMonitorService,
    EnvironmentConfigService,
} from './shared/services';

import { LoggerService } from './shared/services/logger/logger.service';
import { MetricService } from './shared/services/metrics/metrics.service';
import { FleetLoggerService } from './shared/services/fleet-logger/fleet-logger.service';
import { ServiceConfig } from '@aw/vms-client';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CheckForUpdateService } from './shared/services/check-for-update/check-for-update.service';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        RouterModule,
        CoreModule,
        WebsocketModule.config({
            url: environment.ws,
        }),
        MatDialogModule,
        StoreModule.forRoot(fromAppReducer.appReducer),
        EffectsModule.forRoot([
            EndpointConfigEffects,
            MediaDevicesEffects,
            AuthEffects,
            LayoutEffects,
            BatteryEffects,
            CaseEffects,
            PtzEffects,
        ]),
        StoreDevtoolsModule.instrument({
            logOnly: environment.production,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.enabledAutoUpdate,
        }),
    ],
    providers: [
        {
            provide: MatDialogRef,
            useValue: {},
        },
        EnvironmentConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory:
                (
                    environmentConfigService: EnvironmentConfigService,
                    appInitService: AppInitService
                ) =>
                () => {
                    environmentConfigService.init().subscribe(() => {
                        appInitService.bootStrapApp();
                    });
                },
            deps: [
                EnvironmentConfigService,
                AppInitService,
                FleetHandlerService,
                SessionService,
                EndPointService,
                StorageService,
                FleetHandlerService,
                LoggerService,
                FleetLoggerService,
                MetricService,
                IpcService,
                IpcResponseService,
                ActivityMonitorService,
                Store,
            ],
            multi: true,
        },
        {
            provide: ServiceConfig,
            useFactory: (
                environmentConfigService: EnvironmentConfigService
            ) => {
                return {
                    endpoints: [
                        {
                            name: 'createOrUpdateParticipant',
                            url: `${
                                environmentConfigService.getSettings()
                                    .vmsServiceUrl
                            }`,
                            method: 'POST',
                        },
                        {
                            name: 'getLiteToken',
                            url: `${
                                environmentConfigService.getSettings()
                                    .authServiceUrl
                            }/jwt/amwell/lite`,
                        },
                    ],
                };
            },
            deps: [EnvironmentConfigService],
        },
    ],
    bootstrap: [AppComponent],
    exports: [BrowserModule, BrowserAnimationsModule, SharedModule],
})
export class AppModule {
    constructor(private _checkForUpdateService: CheckForUpdateService) {
        !!environment.enabledAutoUpdate && this._checkForUpdateService.init();
    }
}
