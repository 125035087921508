import { distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { selectDeviceOnScreenKeyboardOpened } from '@/shared/storage/selectors/device-selectors';

@Directive({
    selector: '[coreGlobalKeyboardPadding]',
})
export class GlobalKeyboardPaddingDirective implements OnInit {
    constructor(
        private _store: Store,
        private _renderer: Renderer2,
        private _elementRef: ElementRef
    ) {}

    ngOnInit(): void {
        this._store
            .select(selectDeviceOnScreenKeyboardOpened)
            .pipe(distinctUntilChanged())
            .subscribe((isKeyboardOpen: boolean) => {
                if (isKeyboardOpen) {
                    this._renderer.addClass(
                        this._elementRef.nativeElement,
                        'global-keyboard-padding'
                    );
                } else {
                    this._renderer.removeClass(
                        this._elementRef.nativeElement,
                        'global-keyboard-padding'
                    );
                }
            });
    }
}
