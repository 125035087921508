import { Injectable, OnDestroy } from '@angular/core';
import { interval } from 'rxjs';
import { AUDIO_DEVICES, AUDIO_DEVICE_TYPE, FECC_DEVICES } from '../constants';
import { IBatteryStatus } from '../interfaces';
import { selectVideoInputsOrder } from '../storage/selectors';

import { DeviceBase } from './device-base';

@Injectable({ providedIn: 'root' })
export class AmwellTv extends DeviceBase implements OnDestroy {
    private _done: boolean;
    // onActivate() {
    //     super.onActivate();
    //     this.getSystemInfo();
    // }

    init(): void {
        if (this._done) {
            return;
        } // Init can be called more than once to avoid check already executed
        this._done = true;
        super.init();

        this.getSystemInfo();
    }

    getSystemInfo(): void {
        this._ipcService.requestTVStatus();
        this._ipcService.requestLEDStatus();
        this._ipcService.requestPowerStatus();
        this.setTvPrimarySource();

        this._subscription.push(
            interval(60 * 1000).subscribe(() => {
                this._ipcService.requestTVStatus();
            })
        ); // 1 minute
    }

    setTvPrimarySource(): void {
        this._ipcService.setPrimaryTVSource(
            this._sessionService.getConfig().tv_primary_source || 'hdmi1'
        );
    }

    resetState(): void {
        const ringToneVolume =
            this._sessionService.getConfig().RingtoneDefaultVolume !== undefined
                ? this._sessionService.getConfig().RingtoneDefaultVolume
                : 20;
        this.setSpeakerGain({ volume: ringToneVolume });
        this._ipcService.returnToPreviousSource();
        this._ipcService.setLEDTurnOff();
        if (this.turnOffOnDisconnect) {
            this._ipcService.setTVTurnOff();
            this.turnOffOnDisconnect = false;
        }
    }

    getDefaultDevice(deviceOrder, deviceType): any {
        let defaultDevice = null;
        for (const ds of deviceOrder) {
            if (ds) {
                if (typeof ds === 'string') {
                    defaultDevice = this.audioDevices.find(
                        (a) =>
                            a.device_type === deviceType && a.description === ds
                    );
                } else {
                    defaultDevice = this.audioDevices.find(
                        (a) =>
                            a.device_type === deviceType &&
                            a.vid === ds.vid &&
                            a.pid === ds.pid
                    );
                }
                if (defaultDevice) {
                    break;
                }
            }
        }
        return defaultDevice;
    }

    getDefaultAudioInput(): void {
        const deviceOrder = [
            AUDIO_DEVICES.SHURE_MICROPHONE,
            AUDIO_DEVICES.JABRA_MICROPHONE_A,
            AUDIO_DEVICES.JABRA_MICROPHONE_B,
        ];
        if (
            this._sessionService.getConfig() &&
            this._sessionService.getConfig().AudioInput
        ) {
            deviceOrder.unshift(this._sessionService.getConfig().AudioInput);
        }
        return this.getDefaultDevice(deviceOrder, AUDIO_DEVICE_TYPE.SOURCE);
    }

    getDefaultAudioOutput(): void {
        const deviceOrder = [
            this._sessionService.getConfig().AudioOutput,
            AUDIO_DEVICES.JABRA_MICROPHONE_A,
            AUDIO_DEVICES.JABRA_MICROPHONE_B,
        ];
        if (
            this._sessionService.getConfig() &&
            this._sessionService.getConfig().AudioOutput
        ) {
            deviceOrder.unshift(this._sessionService.getConfig().AudioOutput);
        }

        return this.getDefaultDevice(deviceOrder, AUDIO_DEVICE_TYPE.SINK);
    }

    setMicGain(input): void {
        const deviceOrder = [
            this._sessionService.getConfig().AudioInput,
            AUDIO_DEVICES.SHURE_MICROPHONE,
            AUDIO_DEVICES.JABRA_MICROPHONE_A,
            AUDIO_DEVICES.JABRA_MICROPHONE_B,
        ];
        const defaultDevice = this.getDefaultDevice(
            deviceOrder,
            AUDIO_DEVICE_TYPE.SOURCE
        );
        if (defaultDevice) {
            defaultDevice.volume =
                input.volume !== undefined && input.volume !== null
                    ? input.volume
                    : this._sessionService.getConfig().AudioInputDefaultGain !==
                      undefined
                    ? this._sessionService.getConfig().AudioInputDefaultGain
                    : 50;
            super.setMicGain(defaultDevice);
        }
    }

    setSpeakerGain(input): void {
        const deviceOrder = [
            this._sessionService.getConfig().AudioOutput,
            AUDIO_DEVICES.JABRA_MICROPHONE_A,
            AUDIO_DEVICES.JABRA_MICROPHONE_B,
        ];
        const defaultDevice = this.getDefaultDevice(
            deviceOrder,
            AUDIO_DEVICE_TYPE.SINK
        );
        if (defaultDevice) {
            defaultDevice.volume =
                input.volume !== undefined && input.volume !== null
                    ? input.volume
                    : this._sessionService.getConfig()
                          .AudioOutputDefaultVolume !== undefined
                    ? this._sessionService.getConfig().AudioOutputDefaultVolume
                    : 50;
            super.setSpeakerGain(defaultDevice);
        }
    }

    setCriticalBatteryAlertSpeakerGain(): void {
        super.setCriticalBatteryAlertSpeakerGain();
    }

    setAudioDefaultSink(device) {
        const defaultDevice = this.getDefaultDevice(
            [device],
            AUDIO_DEVICE_TYPE.SINK
        );
        if (defaultDevice) {
            const { ctrl_name: ctrlName } = defaultDevice;
            this._ipcService.setAudioDefaultSink(ctrlName);
        }
    }

    setAudioDefaultSource(device) {
        const defaultDevice = this.getDefaultDevice(
            [device],
            AUDIO_DEVICE_TYPE.SOURCE
        );
        if (defaultDevice) {
            const { ctrl_name: ctrlName } = defaultDevice;
            this._ipcService.setAudioDefaultSource(ctrlName);
        }
    }

    setCodecVersion(): void {
        if (!this._usbDevices || this._usbDevices.length <= 0) {
            return;
        }

        this._loggerService.info(
            'amwell-tv:setCodecVersion',
            'setCodecVersion'
        );
        const usrSelVideoInput = this._sessionService.getConfig()?.VideoInput;
        !!usrSelVideoInput &&
            this._loggerService.info(
                'amwell-tv:setCodecVersion',
                'config.VideoInput exists: ' + usrSelVideoInput
            );

        // if user has selected any video input device then take from config and check
        let device = this._usbDevices.find(
            (d) =>
                usrSelVideoInput &&
                d.product &&
                usrSelVideoInput.indexOf(d.product) > -1
        );
        // if user has not selected any video input device then take from selectVideoInputsOrder and check
        !device &&
            selectVideoInputsOrder.find(
                (name) =>
                    (device = this._usbDevices.find(
                        (d) => d?.product?.indexOf(name) >= 0
                    ))
            );
        // if user has not selected any video input device and not in selectVideoInputsOrder then take first Fecc
        !device &&
            (device = this._usbDevices.find((ud) =>
                FECC_DEVICES.find(
                    (d) =>
                        d.productId === ud.pid &&
                        d.vendorId === ud.vid &&
                        d.v4lName.indexOf(ud.product) > -1
                )
            ));
        // If config has video input, set the FECC camera based on config value
        const feccCamera =
            !!device &&
            FECC_DEVICES.find(
                (d) =>
                    d.productId === device.pid &&
                    d.vendorId === device.vid &&
                    d.v4lName.indexOf(device.product) > -1
            );

        !!device &&
            this._loggerService.info(
                'amwell-tv:setCodecVersion',
                'fecc set based on videoInput: ' + JSON.stringify(feccCamera)
            );

        if (feccCamera) {
            this._loggerService.info(
                'amwell-tv:setCodecVersion',
                'fecc camera detected'
            );
            const ptzDevice = {
                camera_connected: +true,
                codec_ver: feccCamera.codecVersion,
            };
            this._feccCameraSelected = true;
            this._metricService.enqueue(ptzDevice);
            this._ptzService.changeCamera(feccCamera);
            this._ptzAxisService.stopPollingAxisCameraStatus();
        } else {
            this._feccCameraSelected = false;

            this._ptzAxisService.init();
            this._ptzAxisService.isCameraConnected();
            this._loggerService.info(
                'amwell-tv:setCodecVersion',
                'no fecc camera. videoInput: ' +
                    (usrSelVideoInput
                        ? 'this.config.VideoInput'
                        : 'no video input')
            );
        }
    }

    changeCodecVersion(device): void {
        const feccCamera = FECC_DEVICES.find(
            (d) =>
                parseInt(d.productId, 16) === parseInt(device.productId, 16) &&
                parseInt(d.vendorId, 16) === parseInt(device.vendorId, 16) &&
                device.label.toLowerCase().indexOf(d.v4lName.toLowerCase()) > -1
        );
        if (feccCamera) {
            const ptzDevice = {
                camera_connected: +true,
                codec_ver: feccCamera.codecVersion,
            };
            this._metricService.enqueue(ptzDevice);
            this._ptzService.changeCamera(feccCamera);
            this._feccCameraSelected = true;
            this._ptzAxisService.stopPollingAxisCameraStatus();
        } else {
            this._feccCameraSelected = false;
            this._ptzAxisService.init();
            this._ptzAxisService.isCameraConnected();
        }
    }

    handleBatteryResponse(): IBatteryStatus {
        const powerStatus = { level: 100, acPowerConnected: true };
        this._metricService.enqueue(powerStatus);
        return powerStatus;
    }
}
