import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { IpcService } from '@shared/services';
import { ConfirmModalComponent } from '@core/components';
import { Icons } from '@shared/enums';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StatisticCollector } from '@services/statistic-collector/statistic-collector.service';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectIsInternetConnected } from '@/shared/storage/selectors';
import { navigateToMain } from '@shared/storage/layout/layout.actions';

@Component({
    selector: 'app-no-network',
    templateUrl: './no-network.component.html',
})
export class NoNetworkComponent implements OnInit, OnDestroy {
    public isOnline = false;
    public dialogRef: MatDialogRef<ConfirmModalComponent>;

    private _isInternetConnectedSub: Subscription;
    private _requestResolveDomainSub: Subscription;
    private _resolveDomainSub: Subscription;

    constructor(
        private _ipcService: IpcService,
        private _matDialog: MatDialog,
        private _statisticCollector: StatisticCollector,
        private _store: Store
    ) {}

    ngOnInit(): void {
        this.checkNetworkStatus();
        this.requestResolveDomain();
    }

    checkNetworkStatus(): void {
        this._isInternetConnectedSub = interval(10000)
            .pipe(
                withLatestFrom(this._store.select(selectIsInternetConnected)),
                map((values) => values[1])
            )
            .subscribe((isInternetConnectedFlag) => {
                if (isInternetConnectedFlag) {
                    this.isOnline = true;
                    this.returnToHomepage();
                }
            });
    }

    // tslint:disable-next-line:typedef
    requestResolveDomain() {
        this._requestResolveDomainSub = interval(15000).subscribe(() => {
            this._resolveDomainSub = this._ipcService
                .requestResolveDomain({
                    component: 'No Network Component',
                    message: 'REDIRECT_TO_HOMEPAGE_ON_RESOLVE',
                })
                .pipe(first())
                .subscribe((response) => {
                    if (response.success) {
                        this.returnToHomepage();
                    }
                });
        });
    }

    returnToHomepage(): void {
        this._statisticCollector.saveEvent(
            'No Network',
            'Return to dashboard',
            'Return to dashboard via button Return to dashboard'
        );
        this._store.dispatch(navigateToMain());
    }

    restartDevice(): void {
        this.dialogRef = this._matDialog.open(ConfirmModalComponent, {
            panelClass: ['aw-modal', 'modal-md'],
            data: {
                title: 'Restart',
                icon: Icons.Restart,
                confirmText: 'Restart',
                body: `<p>Are you sure you would like to Restart the device?</p>`,
            },
        });

        this._statisticCollector.saveEvent(
            'No Network',
            'Initiate restart device',
            'Initiate restart device via button Restart'
        );

        this.dialogRef
            .afterClosed()
            .pipe(first())
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this._statisticCollector.saveEvent(
                        'No Network',
                        'Restart device',
                        'Restart device via button Restart_Restart modal dialog'
                    );
                    this._ipcService.restartDevice();
                }
            });
    }

    shutdownDevice(): void {
        this.dialogRef = this._matDialog.open(ConfirmModalComponent, {
            panelClass: ['aw-modal', 'modal-md'],
            data: {
                title: 'Turn Off',
                icon: Icons.Shutdown,
                confirmText: 'Turn Off',
                body: `<p>Are you sure you would like to Turn Off the device?</p>`,
            },
        });

        this.dialogRef
            .afterClosed()
            .pipe(first())
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this._ipcService.shutdownDevice();
                } else {
                    this._statisticCollector.saveEvent(
                        'No Network',
                        'Cancel turn off device',
                        'Cancel turn off device via button Cancel_Turn off modal dialog'
                    );
                }
            });

        this._statisticCollector.saveEvent(
            'No Network',
            'Initiate turn off device',
            'Initiate turn off device via button Turn off'
        );
    }

    ngOnDestroy(): void {
        if (this._isInternetConnectedSub) {
            this._isInternetConnectedSub.unsubscribe();
        }
        if (this._requestResolveDomainSub) {
            this._requestResolveDomainSub.unsubscribe();
        }
        this._resolveDomainSub?.unsubscribe();
    }
}
