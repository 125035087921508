import { Injectable, OnDestroy } from '@angular/core';
import {
    IAuthenticationResponse,
    IConsumerSettings,
    IDeviceInfo,
    IEndpointConfiguration,
    IEndpointSettings,
    IEnterpriseSettings,
    INovaClientUserAgent,
} from '@shared/interfaces';

import { EndpointTypes } from '@shared/enums';
import { StorageService } from '../storage';
import { Subscription } from 'rxjs';
import { AmwellTv } from '@/shared/device/amwell-tv';
import { AmwellCart250 } from '@/shared/device/amwell-cart-250';
import { AmwellCart500 } from '@/shared/device/amwell-cart-500';
import UAParser from 'ua-parser-js';

@Injectable({ providedIn: 'root' })
export class SessionService implements OnDestroy {
    private _data: IAuthenticationResponse;
    private _deviceInfo: IDeviceInfo;
    private _config: IEndpointConfiguration;
    private _fleetToken: string;
    private _oneToken: string;
    private _environment: string;
    private _codec: string;
    private _standbyState: boolean;
    private _deviceSerialNumber: string = null;
    private _firmwareVersion: string = null;
    private _subscriptions: Subscription[] = [];
    private _accessToken: string;
    private _deviceInstance: AmwellTv | AmwellCart250 | AmwellCart500;
    constructor(private _storageService: StorageService) {
        this._subscriptions.push(
            _storageService.subscribeOnConfigChange(
                (config: IEndpointConfiguration) => {
                    this._config = { ...config };
                    this._config.VideoInput =
                        config?.DefaultVideoInput === false
                            ? this._config.VideoInput
                            : '';
                    this._config.AudioInput =
                        config?.DefaultAudioInput === false
                            ? this._config.AudioInput
                            : '';
                    this._config.AudioOutput =
                        config?.DefaultAudioOutput === false
                            ? this._config.AudioOutput
                            : '';
                }
            ) as Subscription
        );

        this._subscriptions.push(
            _storageService.subscribeOnDeviceInfo((deviceInfo: IDeviceInfo) => {
                this._deviceInfo = deviceInfo;
            })
        );
    }
    ngOnDestroy(): void {
        this._subscriptions.forEach((s) => s && s.unsubscribe());
    }

    set(data: IAuthenticationResponse): void {
        this._data = data;
        const endpointSettings = { ...data?.endpoint };
        // this._storageService.setConfig(JSON.parse(data.endpoint.configuration));
        const { configuration } = endpointSettings;
        this._storageService.setEndpointConfig(
            configuration ? JSON.parse(configuration) : '{}'
        );
        delete endpointSettings.configuration;
        this._storageService.setEndpoint(endpointSettings);
    }

    public get deviceInstance(): AmwellTv | AmwellCart250 | AmwellCart500 {
        return this._deviceInstance;
    }

    public set deviceInstance(value: AmwellTv | AmwellCart250 | AmwellCart500) {
        this._deviceInstance = value;
    }

    public get deviceInfo(): IDeviceInfo {
        return this._deviceInfo;
    }

    public set deviceSerialNumber(serial: string) {
        this._deviceSerialNumber = serial;
    }

    public get deviceSerialNumber(): string {
        return this._deviceSerialNumber;
    }

    public set firmwareVersion(fwVersion: string) {
        this._firmwareVersion = fwVersion;
    }

    public get firmwareVersion(): string {
        return this._firmwareVersion;
    }

    public get allowCaseCreation(): boolean {
        return !this._config?.disable_case_creation;
    }

    public get isAutoAnswer(): boolean {
        return this._config ? this._config.auto_answer || false : false;
    }

    public getNovaClientUserAgent(): INovaClientUserAgent {
        const parser = new UAParser('user-agent');
        parser.setUA(navigator.userAgent);

        return {
            browser: parser.getBrowser().name,
            browserVersion: parser.getBrowser().version,
            deviceModel: this.deviceInfo.deviceType,
            deviceType: 'Carepoint',
            os: 'NOVA',
            osVersion: this.deviceInfo.osVersion,
        };
    }

    getEndpoint(): IEndpointSettings {
        return this._data?.endpoint;
    }

    public get endpoint(): IEndpointSettings {
        return this._data ? this._data.endpoint : null;
    }

    getConfig(): IEndpointConfiguration {
        return this._config;
    }

    public get config(): IEndpointConfiguration {
        return this._config;
    }

    updateConfig(key: string, value: string | number | boolean): void {
        this._storageService.updateEndpointConfig(key, value);
    }

    public get enterprise(): IEnterpriseSettings {
        return this._data && this._data.enterprise;
    }

    getEnterprise(): IEnterpriseSettings {
        return this._data && this._data.enterprise;
    }

    getEndpointType(): EndpointTypes {
        return this._data.endpoint.endpoint_type_id;
    }

    public get isC500(): boolean {
        return this.getEndpointType() === EndpointTypes.C500;
    }

    public get endpointType(): EndpointTypes {
        return this._data?.endpoint?.endpoint_type_id;
    }

    public get isScreenSaverEnabled(): boolean {
        return this._config.enable_screensaver;
    }

    public get screenSaverTimeout(): number {
        return this._config && this._config.screensaver_timeout;
    }

    public get isTurnOffDisplayEnabled(): boolean {
        return this._config && this._config.enable_turn_off_display;
    }

    public get turnOffDisplayTimeout(): number {
        return this._config && this._config.turn_off_display_timeout;
    }

    public get isTVKit(): boolean {
        return this.endpointType === EndpointTypes.Intmtvkit;
    }

    set standbyState(standbyState: boolean) {
        this._standbyState = standbyState;
    }

    get standbyState(): boolean {
        return this._standbyState;
    }

    getConsumerAccount(): IConsumerSettings {
        return this._data.consumer;
    }

    public get trackingCode(): number | boolean {
        return this._data?.enterprise?.apgar_code_id || 1;
    }

    public get fleetUrl(): string {
        return this._config.fleet_url;
    }

    public get deviceId(): string {
        return this._data?.endpoint?.endpoint_device_id;
    }

    public get onScreenKeyboard(): boolean {
        const { onscreen_keyboard: onScreenKeyboard } = this._config || {};
        return onScreenKeyboard;
    }

    public get enableFleetTimeout(): boolean {
        return this._data.endpoint.enable_fleet_timeout;
    }

    public set accessToken(value: string) {
        this._accessToken = value;
    }

    public get accessToken(): string {
        return this._accessToken;
    }

    public get videoInput(): string | null {
        return this._config ? this._config.VideoInput : null;
    }

    public get audioInput(): string | null {
        return this._config ? this._config.AudioInput : null;
    }

    public get audioOutput(): string | null {
        return this._config ? this._config.AudioOutput : null;
    }

    public get fleetToken(): string {
        return this._fleetToken;
    }

    public set fleetToken(value: string) {
        this._fleetToken = value;
    }

    public get oneToken(): string {
        return this._oneToken;
    }

    public set oneToken(value: string) {
        this._oneToken = value;
    }

    public get environment(): string {
        return this._environment;
    }

    public set environment(value: string) {
        this._environment = value;
    }

    public set codecVersion(codec: string) {
        this._codec = codec;
    }

    public get codecVersion(): string {
        return this._codec;
    }
}
