import { Location } from '@angular/common';
import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { confirmCancelCase, selectServiceLine } from '@shared/storage';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoggerService } from '@services/logger';

@Component({
    selector: 'sticky-footer',
    templateUrl: './sticky-footer.component.html',
})
export class StickyFooterComponent implements OnInit, OnDestroy {
    @Input() cancelDisabled = false;
    @Input() nextDisabled = false;
    @Input() previousDisabled = false;
    @Input() isNextProgressButton = false;

    @Output() nextCase = new EventEmitter<void>();

    @ContentChild('externalControls') externalControlsRef: ElementRef;

    public serviceLine;
    public serviceLineName;
    public serviceLineTypeId;

    private _subscriptions: Subscription[] = [];

    private _destroy$: ReplaySubject<number> = new ReplaySubject<number>(1);

    constructor(
        private _location: Location,
        private _store: Store,
        private _loggerService: LoggerService
    ) {}

    ngOnInit(): void {
        this._store
            .select(selectServiceLine)
            .pipe(takeUntil(this._destroy$))
            .subscribe((val) => (this.serviceLine = val));

        const { name = '', service_line_type_id: serviceLineTypeId = '' } =
            this.serviceLine || {};
        this.serviceLineName = name;
        this.serviceLineTypeId = serviceLineTypeId;
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(
            (subscription: Subscription) =>
                subscription && subscription.unsubscribe()
        );

        this._destroy$.next();
        this._destroy$.complete();
    }

    cancel(): void {
        this._store.dispatch(confirmCancelCase());
    }

    previous(): void {
        this._loggerService.log('CASE_FOOTER', {
            details: {
                functionCalled: 'previous',
                actionToPerform: 'navigateToPreviousBrowserLocation',
            },
        });
        this._location.back();
    }

    next(): void {
        if (this.nextDisabled) {
            return;
        }

        this.nextCase.emit();
    }
}
